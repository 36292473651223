




















































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';

import { appName, apiUrl } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess, readNavItems, readFileNotifications } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer, commitAddNotification, commitSetFileNotifications, commitSetCreatingArchive } from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';
import { Socket } from 'vue-socket.io-extended';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

interface IBreadcrumb {
    path: string;
    to: string;
    text: string;
    disabled: boolean;
}

@Component({
  components: {
    FilePond,
  },
})
export default class Main extends Vue {
  public appName = appName;

  @Socket() // --> listens to the event by method name, e.g. `connect`
  public connect() {
    // console.log('connection established');
    // Join a room here?

  }

  @Socket('file-upload')
  public onFileUpload(fileInfo) {
    // console.log(fileInfo);
    commitAddNotification(this.$store, { content: `${fileInfo.file_type}, ${fileInfo.test_count} tests, ${fileInfo.new_files} new`, color: 'success' });
    // this.updateTestView();
    // commitSetTest(this.$store, JSON.parse(testInfo));
  }

  @Socket('file-exists')
  public onFileExists() {
    commitAddNotification(this.$store, { content: 'This file was already uploaded', color: 'error' });
    // this.updateTestView();
    // commitSetTest(this.$store, JSON.parse(testInfo));
  }

  @Socket('created-archive')
  public onNewArchive(fileInfo) {
    // console.log(JSON.parse(testInfo));
    commitAddNotification(this.$store, { content: `Your archive ${fileInfo.filename} is ready to download`, color: 'success' });

    const count = readFileNotifications(this.$store);
    commitSetFileNotifications(this.$store, count + 1);
    commitSetCreatingArchive(this.$store, false);
  }

  get fileNotifications() {
    return readFileNotifications(this.$store);
  }

  public handleFilePondInit() {
    // console.log('FilePond has initialized');
  }

  public handleFilePondProcess(error, file) {
    // console.log(error);
    if (error.code === 415) {
      commitAddNotification(this.$store, { content: 'Not a valid GeneXpert Dx File', color: 'error' });
    }
  }

  public get serverOptions() {
    return {
      url: `${apiUrl}/api/v1/files/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
    };
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get navItems() {
    const pathArray: string[] = this.$route.path.split('/');
    pathArray.shift();
    // console.log(this.$route.matched);
    const breadcrumbs = pathArray.reduce((breadcrumbArray: IBreadcrumb[], path, idx) => {
      const breadcrumb = {
        path,
        to: breadcrumbArray[idx - 1]
          ? '/' + breadcrumbArray.map((e) => e.path).join('/') + '/' + path
          : '/' + path,
        text: idx === pathArray.length - 1
          ? this.$route.matched[this.$route.matched.length - 1].meta.breadCrumb || path
          : path,
        disabled: false,
      };
      // console.log(`${idx} == ${pathArray.length - 1}`);
      // console.log(this.$route.matched[idx].meta.breadCrumb);
      breadcrumbArray.push(breadcrumb);
      return breadcrumbArray;
    }, []);

    return breadcrumbs;
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
